












import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import SessionCmsQaComponent from '@/components/session-cms/SessionCmsQaComponent.vue';
import { Getter, namespace } from 'vuex-class';
import ActionType from '@/utils/enums/ActionType';
import { SessionFilter } from '@/graphql/_Filters/SessionFilter';
import Session from '@/models/graphql/Session';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import GenericEvent from '@/utils/types/GenericEvent';
import SessionSettingType from '@/utils/enums/SessionSettingType';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';

const sessionStore = namespace('SessionStore');
const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    SessionCmsQaComponent,
  },
})
export default class SessionLiveQnaWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget, VueBaseNotify) {
  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({ required: false, default: null })
  private entityCode!: string;

  @Prop({ required: false, default: null })
  private sessionId!: string;

  @Prop({ required: false, default: null })
  private schemaCode!: string;

  @sessionStore.Action(ActionType.GET_ONE)
  private getSession!: (payload: {
    filter: { editionMappings: { editionSession: { schemaCode: string; id: string } } };
  } | SessionFilter) => Promise<Session | undefined>;

  @sessionStore.Getter
  private session!: Session;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void; }) => void;

  private localSessionUid = '';

  private canShowQna: boolean | undefined = false;

  private FeatureKeys = FeatureKeys;

  private sessionSettingType = SessionSettingType;

  private dynamicStoreName = `Qna${+new Date()}Store`;

  private get uid(): string | null {
    let r = this.entityCode;
    if (this.entityCode) {
      const matches = this.entityCode.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
    }
    return this.entityCode;
  }

  private get formattedSessionId(): string | null {
    let r = this.sessionId;
    if (this.sessionId) {
      const matches = this.sessionId.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
    }
    return this.sessionId;
  }

  private get formattedSchemaCode(): string | null {
    let r = this.schemaCode;
    if (this.schemaCode) {
      const matches = this.schemaCode.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
    }
    return this.schemaCode;
  }

  private get isQnaFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_LIVE_QNA_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_LIVE_QNA_FEATURE).enabled
      && !!this.canShowQna;
  }

  created(): void {
    this.setDataConfig();
    this.notifyEvents = [
      NotificationEventType.SESSION_SETTINGS_CHANGE,
    ];
  }

  beforeDestroy(): void {
    if (this.uid && this.uid !== '') {
      this.unsubscribeGenericEventPointer(`session-settings-${this.uid}`);
    } else if (this.formattedSessionId) {
      this.unsubscribeGenericEventPointer(`session-settings-${this.formattedSessionId}`);
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    const extraData = JSON.parse(event.extra);
    if (event
      && event.type === NotificationEventType.SESSION_SETTINGS_CHANGE
      && this.uid && event.entityId === this.uid
      && extraData.settingType
      && extraData.settingType === this.sessionSettingType.QNA) {
      this.canShowQna = extraData.isActivated;
    }
  }

  @Watch('uid')
  @Watch('formattedSessionId')
  private onUidChange(): void {
    if (this.uid && this.uid !== '') {
      this.getSession({ filter: { uid: this.uid } }).then((session) => {
        if (session) {
          this.localSessionUid = session.uid;
          this.canShowQna = session.showQNA;
        }
      });
      this.genericEvent({ channel: `session-settings-${this.uid}` });
    } else if (this.formattedSessionId && this.formattedSchemaCode) {
      this.getSession({
        filter: {
          editionMappings: {
            editionSession: {
              id: parseInt(this.formattedSessionId, 10),
              schemaCode: this.formattedSchemaCode,
            },
          },
        },
      }).then((session) => {
        if (session) {
          this.localSessionUid = session.uid;
          this.canShowQna = session.showQNA;
        }
      });
      this.genericEvent({ channel: `session-settings-${this.formattedSessionId}` });
    }
  }
}
